<template>
  <div class="select-component">
    <div class="select-component__wrapper">
      <component :is="component">
        <label class="select-component__label"
               :for="id">{{label}}</label>
        <div :id="id"
             v-click-outside="close"
             class="select"
             :class="[show ? 'select-opened' : '', scroll ? 'select-component-with-scroll' : '']"
             v-on:click="toggle">
          <Icon class="select-icon"
                xlink="arrow-navigation"
                :rotate="show ? 0 : 180"
                viewport="0 0 20 20"/>
          <div class="selected">
            {{items.find(item => item.id === index)[itemKey]}}
          </div>
          <div v-if="show"
               class="select-list">
            <div v-for="item in items"
                 :key="item.id"
                 v-on:click="select(item.id)"
                 class="select-item">{{item[itemKey]}}
            </div>
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
  props: {
    id: {},
    index: {},
    label: {},
    array: {},
    scroll: {},
    itemKey: {},
    component: {
      default: () => 'Column',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    items() {
      return this.array;
    },
  },
  created() {
    if (this.items.every(item => item.id !== this.index)) {
      this.$emit('change-index', this.items[0].id);
    }
  },
  methods: {
    close() {
      this.show = false;
    },
    toggle() {
      this.show = !this.show;
    },
    select(id) {
      this.$emit('change-index', id);
    },
  },
};
</script>
